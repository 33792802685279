// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-logo {
  height: 400px;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .home-logo {
    height: 280px;
  }
}

.sports-directory-logo {
  max-width: 300px;
  width: 100%;
  height: auto;
}

.homepage-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: url("/assets/background.jpeg") no-repeat center center fixed;
  background-size: cover;
}
.homepage-wrapper > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 15px;
}
.homepage-wrapper > div > div {
  width: 100%;
}

.social-media a {
  font-size: 3em;
  padding: 0 15px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./apps/outdoorPro/src/app/home/home.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;AACF;AACE;EAJF;IAKI,aAAA;EAEF;AACF;;AACA;EACE,gBAAA;EACA,WAAA;EACA,YAAA;AAEF;;AACA;EACE,aAAA;EACA,eAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,wEAAA;EACA,sBAAA;AAEF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,0CAAA;EACA,aAAA;AAEJ;AAAI;EACE,WAAA;AAEN;;AAIE;EACE,cAAA;EACA,eAAA;EACA,eAAA;AADJ","sourcesContent":[".home-logo {\n  height: 400px;\n  margin: 0 auto;\n\n  @media screen and (max-width: 768px) {\n    height: 280px;\n  }\n}\n\n.sports-directory-logo {\n  max-width: 300px;\n  width: 100%;\n  height: auto;\n}\n\n.homepage-wrapper {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  background: url(\"/assets/background.jpeg\") no-repeat center center fixed;\n  background-size: cover;\n\n  > div {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    background-color: rgba($color: #ffffff, $alpha: 0.6);\n    padding: 15px;\n\n    > div {\n      width: 100%;\n    }\n  }\n}\n\n.social-media {\n  a {\n    font-size: 3em;\n    padding: 0 15px;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
